import React from 'react';
import '../styles/ClientLogos.css';
import a from '../assets/01.webp';
import c from '../assets/03.png';
import d from '../assets/04.jpg';
import e from '../assets/05.jpeg';

const ClientLogos = () => {
  const logos = [a, c, d, e];
  // Ensure that the logos are duplicated to cover the scrolling area without gaps
  const logosToShow = [...logos, ...logos,...logos,...logos,...logos,...logos];

  return (
    <div className="container">
      <h1>Our Clients</h1>
      <div className="logo-scroll">
        <div className="logo-scroll-inner">
          {logosToShow.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Client Logo ${index + 1}`}
              className="logo"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientLogos;
