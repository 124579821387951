import '../styles/Ourclientsfull.css';
// import ContactusImage from './Ourclients.png';

function Ourclients() {
  const clients = [
    "1", "2", "3",
    "4", "5", "6",
    "7", "8", "9",
  ];

  return (
    <div className="App">
      <div className="main-photo">
        {/* <img src={ContactusImage} alt="Main" /> */}
      </div>
      <div className="clients-grid">
        {clients.map((client, index) => (
          <div key={index} className="client-logo">
            <img src={`./images/${client}.png`} alt={`${client} logo`} />
          </div>  
        ))}
      </div>
    </div>
  );
}

export default Ourclients;
