import React from "react";
import '../styles/projectgallery.css';
import galleryImage from '../assets/project gallery.png'; // Adjust the path as needed
import Proj1 from '../assets/pro1.jpg';
import Proj2 from '../assets/pro2.jpg';
import Proj3 from '../assets/pro3.jpg';
import Proj4 from '../assets/pro4.png'; 
import Proj5 from '../assets/pro5.jpg'; 
import Proj6 from '../assets/pro6.jpg'; 
import Proj7 from '../assets/sla.jpg'; 

const projects = [
  {
    id: 1,
    image: Proj1,
    title: 'Civil Work Slab Installation',
    description: 'Executed a civil work slab installation project at Pecasa, ensuring high standards of structural integrity and precision.',
  },
  {
    id: 2,
    image: Proj6,
    title: 'Fire Fighting Work',
    description: 'Completed fire fighting system installation at Rathna Biolife, enhancing safety and compliance with industry regulations.',
  },
  {
    id: 3,
    image: Proj5,
    title: 'Warehouse Lighting and Electrical',
    description: 'Designed and implemented warehouse lighting and electrical systems at Wingtech to improve operational efficiency and safety.',
  },
  {
    id: 4,
    image: Proj7,
    title: 'High-Precision Civil Work Slab Installation',
    description: 'Carried out a precise and high-quality civil work slab installation project, focusing on durability and accuracy.',
  },
  {
    id: 5,
    image: Proj2,
    title: 'Lighting and Electrical Work ',
    description: 'Delivered comprehensive lighting and electrical solutions for Coca-Cola’s warehouse to optimize performance and energy efficiency.',
  },
  {
    id: 6,
    image: Proj3,
    title: 'Advanced Firefighting System Installation',
    description: 'Installed state-of-the-art firefighting systems at Bombay Steel to enhance safety measures and ensure adherence to safety standards.',
  },
];

function ProjectGallery() {
  return (
    <div className="App">
      <div className="main-photo">
        <img src={galleryImage} alt="Main" />
      </div>
      <div className="projects">
        {projects.map(project => (
          <div className="project-item" key={project.id}>
            <img src={project.image} alt={project.title} className="project-image" />
            <h3>{project.title}</h3>
            <p>{project.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProjectGallery;
