import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaLinkedinIn, FaYoutube, FaTwitter } from 'react-icons/fa';
import { MdEmail, MdPhone } from 'react-icons/md';
import '../styles/Navbar.css';
import logo from '../assets/5bro.png'; // Correct import path for the logo image

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header>
      {/* Top bar - blue
      <div className="bg-navy-blue text-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center py-2 text-sm">
            <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0">
              <a href="mailto:projects@fivebroelectricalsolutions.com" className="email-link flex items-center">
                <MdEmail className="mr-1" />
                projects@fivebroelectricalsolutions.com
              </a>
              <div className="flex flex-col md:flex-row space-x-2">
                <a href="tel:+918147077971" className="phone-link flex items-center">
                  <MdPhone className="mr-1" />
                  +91 8147077971
                </a>
                <a href="tel:+919901540510" className="phone-link flex items-center">
                  <MdPhone className="mr-1" />
                  +91 9901540510
                </a>
              </div>
            </div>
            <div className="flex space-x-4 mt-2 md:mt-0">
              <a href="https://facebook.com" aria-label="Facebook" className="social-icon">
                <FaFacebookF />
              </a>
              <a href="https://linkedin.com" aria-label="LinkedIn" className="social-icon">
                <FaLinkedinIn />
              </a>
              <a href="https://youtube.com" aria-label="YouTube" className="social-icon">
                <FaYoutube />
              </a>
              <a href="https://twitter.com" aria-label="Twitter" className="social-icon">
                <FaTwitter />
              </a>
            </div>
          </div>
        </div>
      </div> */}

      {/* Main navigation - white */}
      <div className="bg-white text-black">
        <div className="container mx-auto px-4">
          <nav className="flex justify-between items-center py-4">
            <Link to="/" className="flex items-center">
              <img src={logo} alt="FiveBRO Electrical Solutions" className="h-14" />
            </Link>

            {/* Desktop menu */}
            <div className="hidden md:flex items-center space-x-6">
              <Link to="/" className="desktop-menu-link">Home</Link>
              <Link to="/about" className="desktop-menu-link">About us</Link>
              <Link to="/services" className="desktop-menu-link">Services</Link>
              <Link to="/clients" className="desktop-menu-link">Clients</Link>
              <Link to="/projects" className="desktop-menu-link">Project Gallery</Link>
              <Link to="/contact" className="button-3d">Contact Us</Link>
            </div>

            {/* Mobile menu button */}
            <button 
              className="md:hidden"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Toggle menu"
            >
              ☰
            </button>
          </nav>

          {/* Mobile menu */}
          {isMenuOpen && (
            <div className="md:hidden">
              <Link to="/" className="block py-2">Home</Link>
              <Link to="/about" className="block py-2">About us</Link>
              <Link to="/services" className="block py-2">Services</Link>
              <Link to="/clients" className="block py-2">Clients</Link>
              <Link to="/projects" className="block py-2">Project Gallery</Link>
              <Link to="/contact" className="block py-2">Contact Us</Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
