import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/OurProjects.css';
import project1 from '../assets/pro1.jpg';
import project2 from '../assets/pro2.jpg';
import project3 from '../assets/pro3.jpg';

const OurProjects = () => {
  const navigate = useNavigate();

  const projects = [
    {
      image: project1,
      title: 'Civil Work Slab Installation',
      description: 'Completed civil work slab installation, ensuring structural integrity and precision.',
    },
    {
      image: project2,
      title: 'Warehouse Lighting and Electrical Work',
      description: 'Implemented comprehensive lighting and electrical solutions to enhance warehouse efficiency.',
    },
    {
      image: project3,
      title: 'Firefighting System Installation',
      description: 'Installed advanced firefighting systems to ensure safety and compliance with industry standards.',
    },
  ];

  const handleReadMoreClick = () => {
    navigate('/projects'); // Redirects to the project gallery full page
  };

  return (
    <div className="our-projects-container">
      <h1 className="our-projects-title">
        Our <span>Projects</span>
      </h1>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <img src={project.image} alt={project.title} className="project-image" />
            <div className="project-info">
              <h2 className="project-title">{project.title}</h2>
              <p className="project-description">{project.description}</p>
            </div>
          </div>
        ))}
      </div>
      {/* Read More Button in Center */}
      <div className="read-more-btn-container">
        <button className="read-more-btn" onClick={handleReadMoreClick}>
          Explore Our Full Portfolio
        </button>
      </div>
    </div>
  );
};

export default OurProjects;
