import React from 'react';
import '../styles/Footer.css';
import logo from '../assets/5bro.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <img src={logo} alt="LiveBro Electrical Solutions" className="footer-logo" />
          <p className="tagline">Enlightening Your Electric Needs</p>
          <p className="description">
            We specialize in providing electrical contracting services for a wide
            range of projects including commercial offices, institutional,
            biomedical, and large residential developments.
          </p>
          <p className="copyright">© Copyright FiveBro Electrical Solutions 2024. All Rights Reserved.</p>
        </div>
        <div className="quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/clients">Our Clients</Link></li>
            <li><Link to="/projects">Project Gallery</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </div>
        <div className="contact-info">
          <p><FontAwesomeIcon icon={faMapMarkerAlt} /> #40, Markendaya Nagara, Opposite of Brigade Bricklane, Yelahanka, Bangalore 560064.</p>
          <p><FontAwesomeIcon icon={faEnvelope} /> projects@fivebroelectricalsolutions.com</p>
          <p><FontAwesomeIcon icon={faPhone} /> +91 8147077971 / 9901540510</p>
          <div className="social-icons-box">
            <a href="#" className="icon facebook"><FontAwesomeIcon icon={faFacebook} /></a>
            <a href="#" className="icon twitter"><FontAwesomeIcon icon={faTwitter} /></a>
            <a href="#" className="icon linkedin"><FontAwesomeIcon icon={faLinkedin} /></a>
            <a href="#" className="icon instagram"><FontAwesomeIcon icon={faInstagram} /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
