import React, { useState, useEffect } from 'react';
import Banner1 from '../assets/Banner1.png';
import Banner2 from '../assets/Banner2.png';
import Banner3 from '../assets/Banner 4.png';
import Banner4 from '../assets/Banner 1.png';
import Banner5 from '../assets/Banner 2.png';
import Banner6 from '../assets/Banner 6 (1).png';
import '../styles/Bannerscroll.css';

const BannerScroll = () => {
  const [currentBanner, setCurrentBanner] = useState(0);

  const banners = [
    {image: Banner1,},{image: Banner2,},{image: Banner3,},{image: Banner4,},{image: Banner5,},{image: Banner6,}
    // Add more banner objects here as needed
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prev) => (prev + 1) % banners.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [banners.length]);

  return (
    <div className="banner-scroll">
      <h1 className="main-title">Power Up with FiveBro Electrical Solutions</h1>
      <div className="banner-container">
        {banners.map((banner, index) => (
          <div
            key={index}
            className={`banner ${index === currentBanner ? 'active' : ''}`}
          >
            <img src={banner.image} alt={banner.title} />
            <div className="banner-content">
              <h2>{banner.title}</h2>
              <p>{banner.subtitle}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="banner-dots">
        {banners.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentBanner ? 'active' : ''}`}
            onClick={() => setCurrentBanner(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default BannerScroll;
