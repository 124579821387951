import React, { useRef } from 'react';
import '../styles/Contact.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_fbfyjyf', 'template_74lj5jb', form.current, {
        publicKey: 'VbM9KBv32EsXWBLCy',
      })
      .then(
        () => {
          toast.success('Form submitted successfully! Thank you.');
        },
        (error) => {
          console.log('FAILED...', error.text);
          toast.error('There was an error submitting the form. Please try again.');
        }
      );
  };

  return (
    <div className="contact-container">
      <div className="contact-info">
        <h2>Contact Information</h2>
        <p><i className="fas fa-phone-alt"></i> +91 8147077971 / 9901540510</p>
        <p><i className="fas fa-envelope"></i> projects@fivebroelectricalsolutions.com</p>
        <p><i className="fas fa-map-marker-alt"></i> #40, Markendaya Nagar, Opposite of Brigade Bricklane, Yelahanka, Bangalore 560064.</p>
        <div className="social-icons">
          <i className="fab fa-facebook-f"></i>
          <i className="fab fa-twitter"></i>
          <i className="fab fa-instagram"></i>
        </div>
      </div>
      <div className="form-container">
        <h2>Send Us Message</h2>
        <form ref={form} onSubmit={sendEmail}>
          <div className="form-row">
            <div className="form-group">
              <input type="text" name="from_name" placeholder="Full Name" required />
            </div>
            <div className="form-group">
              <input type="email" name="from_email" placeholder="Email" required />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <input type="tel" name="phone" placeholder="Phone No." required />
            </div>
            <div className="form-group">
              <input type="text" name="business_name" placeholder="Business Name" required />
            </div>
          </div>
          <div className="form-group">
            <textarea  placeholder="Describe your requirement in Brief" ></textarea>
          </div>
          <button type="submit" className="submit-btn">Submit</button>
        </form>
      </div>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.9817676806797!2d77.62923047359143!3d13.100341512045647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae19783101c55f%3A0xe29d01e211e659f8!2sFivebro%20Electrical%20Solutions!5e0!3m2!1sen!2sin!4v1726292287592!5m2!1sen!2sin"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="map-frame"
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default ContactUs;
