import React, { useEffect } from 'react';
import '../styles/Hero.css'; // Ensure this path is correct
import '../App.css'; // Ensure this path is correct
import profile from '../assets/bulb.png'; // Ensure this path is correct

const HeroSection = () => {
  useEffect(() => {
    const handleScroll = () => {
      const heroContent = document.querySelector('.hero-content');
      if (window.scrollY > 100) {
        heroContent.classList.add('scrolled');
      } else {
        heroContent.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="hero-container">
      <div className="hero-content">
        <h1 className="hero-title">
          <span className="blue-text">Enlightening Your</span><br />
          <span className="blue-text">Electric Needs</span>
        </h1>
        <p className="hero-description">
          We specialize in providing electrical contracting services for a wide 
          range of projects including commercial, industrial, institutional, 
          biomedical, and large residential developments.
        </p>
      </div>
      <div className="hero-image-container">
        <img className="hero-image" src={profile} alt="FiveBRO Electrical Solutions" />
      </div>
    </div>
  );
};

export default HeroSection;
