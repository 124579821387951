import '../styles/Ourclientsfull.css';
import ClientsImage from '../assets/ourClients.png';

import Img1 from '../assets/images/1.png';
import Img2 from '../assets/images/2.png';
import Img3 from '../assets/images/3.png';
import Img4 from '../assets/images/4.png';
import Img5 from '../assets/images/5.png';
// import Img6 from '../assets/images/6.png';
import Img7 from '../assets/images/7.png';
import Img8 from '../assets/images/8.png';

function Ourclients() {
  const clients = [
    Img1, Img2, Img3, Img4, Img5,
    Img7, Img8,
  ];

  return (
    <div className="clients-container">
      <div className="header-image">
        <img src={ClientsImage} alt="Main" />
      </div>
      <div className="clients-gallery">
        {clients.map((client, index) => (
          <div key={index} className="client-card">
            <img src={client} alt={`Client ${index + 1} logo`} />
          </div>  
        ))}
      </div>
    </div>
  );
}

export default Ourclients;
