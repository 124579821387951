import React, { useState } from 'react';
import '../styles/OurServices.css';
import service1 from '../assets/fire-fighting_services.jpg';
import service2 from '../assets/warehouse_services.jpg';
import service3 from '../assets/electrical_services.jpg';
import service4 from '../assets/cctv_services.webp';
import { useNavigate } from 'react-router-dom';

const OurServices = () => {
  const [popupData, setPopupData] = useState(null);
  const navigate = useNavigate();

  const services = [
    { title: 'ELECTRICAL SERVICES', image: service3, description: 'High-quality electrical services for industrial and commercial needs.' },
    { title: 'FIRE FIGHTING SERVICES', image: service1, description: 'Expert fire fighting services and equipment installation.' },
    { title: 'E-SECURITY SERVICES', image: service4, description: 'Advanced electronic security systems for your safety.' },
    { title: 'WAREHOUSING SERVICES', image: service2, description: 'Secure and spacious warehousing solutions for your goods.' },
  ];

  const handleCardClick = (index) => {
    setPopupData(services[index]);
  };

  const closePopup = () => {
    setPopupData(null);
  };

  const redirectToServices = () => {
    navigate('/services');
  };

  return (
    <div className="our-services-container">
      <h1 className="our-services-title">Our Services</h1>
      <div className="services-wrapper">
        {services.map((service, index) => (
          <div key={index} className="service-card" onClick={() => handleCardClick(index)}>
            <img src={service.image} alt={service.title} className="service-image" />
            <h3 className="service-title">{service.title}</h3>
          </div>
        ))}
      </div>
      <button className="explore-btn" onClick={redirectToServices}>Explore Our Services</button>

      {popupData && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="popup-close" onClick={closePopup}>&times;</button>
            <img src={popupData.image} alt={popupData.title} className="popup-image" />
            <h3 className="popup-title">{popupData.title}</h3>
            <p className="popup-description">{popupData.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default OurServices;
