import React from 'react';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Services from './components/Services';
import Projects from './components/Projects';
import Clients from './components/Clients';
import Contact from './components/Contact';
import Aboutusfull from './components/Aboutusfull';
import Ourclientsfull from './components/Ourclientsfull';
// import OurServicesfull from './components/OurServicesfull';
import Footer from './components/Footer';
import Bannerscroll from './components/Bannerscroll';
import About from './components/About';
import OurServices from './components/OurServices';
import Ourprojects from './components/Ourprojects';
import ClientLogos from './components/ClientLogos';
import './App.css'; // Import global CSS

const HomePageLayout = () => (
  <div>
    <HeroSection />
    <Bannerscroll />
    <About />
    <OurServices />
    <Ourprojects />
    <ClientLogos />
    <Outlet />  {/* Renders child routes */}
    {/* <Footer /> */}
  </div>
);

function App() {
  return (
    <BrowserRouter>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<HomePageLayout />}>
            <Route index element={<div />} />  {/* Index route for home page */}
          </Route>
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<Aboutusfull />} />
          <Route path="/ourclient" element={<Ourclientsfull />} />
          {/* <Route path="/OurServices" element={<OurServicesfull />} /> */}
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
