import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/AboutUs.css';
import abtlogo from '../assets/abtlogo.png';

const AboutUs = () => {
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const textRef = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    if (containerRef.current) observer.observe(containerRef.current);
    if (imageRef.current) observer.observe(imageRef.current);
    if (textRef.current) observer.observe(textRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
      if (imageRef.current) observer.unobserve(imageRef.current);
      if (textRef.current) observer.unobserve(textRef.current);
    };
  }, []);

  const handleReadMoreClick = () => {
    navigate('/about'); // Update with your actual path
  };

  return (
    <div className="about-us-container" ref={containerRef}>
      <h1 className="about-us-title">About Us</h1>
      <div className="about-us-content">
        <div className="about-us-image" ref={imageRef}>
          <img src={abtlogo} alt="FiveBRO Electrical Solutions" />
        </div>
        <div className="about-us-text" ref={textRef}>
          <p>Welcome to FiveBro Electrical Solutions, your trusted partner for electrical, e-security, and fire-fighting services. Founded by seasoned experts, we serve Karnataka and Andhra Pradesh, bringing extensive experience across residential, commercial, and industrial sectors.</p>
          <p>Our mission is to deliver innovative, efficient, and safe electrical solutions. Our skilled technicians and engineers excel in managing complex systems and installations.</p>
          <p>At FiveBro Electrical Solutions, we push the boundaries of electrical technology, setting new industry standards through strong partnerships and dedication to excellence.</p>
          <button className="read-more-btn" onClick={handleReadMoreClick}>Read More</button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
